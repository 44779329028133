label:has(input.search-bar) {
    margin-bottom: 0px;
}

.label-require {
    color: red !important;
}

.height-input {
    max-height: 36px;
    height: 36px;
    color: #000;
}

.form-add-account h2,
label {
    margin-bottom: 0px;
}

.row:not(:last-child) {
    margin-bottom: 8px;
}

.text-green {
    color: rgba(45, 137, 104, 1);
}

.text-gray {
    color: rgba(136, 152, 170, 1);
}

.text-red {
    color: rgba(236, 12, 56, 1) !important;
}

.btn-no-shadow {
    box-shadow: none;
}

.btn-no-shadow:hover {
    box-shadow: none;
}

.btn-no-shadow:focus {
    box-shadow: none;
}

div.table-responsive {
    overflow-x: hidden !important;
}

td {
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}