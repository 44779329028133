.disable-react-select__dropdown-indicator .react-select__dropdown-indicator,.react-select__indicator-separator{
    display: none;
}
.customSelectMulti div,span{
    font-size: 13px;

}
.customSelectMulti .react-select__control {
    line-height: 15px !important;
}
.react-select__control--custom .react-select__control{
    border: 1px solid red;
}
.customSelectMulti .react-select__placeholder {
    line-height: 15px !important;
}
.customSelectMulti .react-select__value {
    line-height: 15px !important;
}
.customSelectMulti .react-select__label {
    line-height: 15px !important;
}
.customSelectMulti .react-select__input {
    line-height: 15px !important;
}

@media only screen and (max-width: 1240px) {
    .custom-col {
        margin-top: 24px;
    }
    .custom-col label{
        min-width: 175px !important;
        text-align: right;
    }
    .custom-col h4{
        min-width: 175px !important;
        text-align: right;
    }
  }