.container-timeline {
    border-bottom: 1px solid white !important;
    border-color: white;
}
.container-timeline-sub {
    /* background-color: #4690CD; */
    padding: 16px;
    text-align: center;
}
.custom-input-datetime input{ 
    font-size: 13px;
    padding-right: 0px;
    white-space: nowrap;
}
.dropdown-menu {
    /* position: absolute;
    top: 8px !important;
    left: 20px !important; */
}
.custom-position-date-picker .rdtPicker{
    position: absolute;
    right: -24px;
}