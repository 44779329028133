.input-title-tale {
    margin: 0;
    padding: 0;
    height: 24px;
    background: transparent;
    border: 0;
    width: 120px;
    border-bottom: 1px solid rgba(172, 172, 172, 0.7);
    border-radius: 0;
    text-align: center;
    box-shadow: none !important;
    color: #8898aa;
    cursor: pointer;
}
.input-title-tale:focus-visible {
    margin-top: 2px;
    padding: 0;
    height: 24px;
    background: transparent;
    border: 0;
    width: 120px;
    border-bottom: 1px solid rgba(172, 172, 172, 0.7);
    border-radius: 0;
    text-align: center;
    box-shadow: none !important;
    color: #8898aa;
    cursor: pointer;
}


.input-title-tale::placeholder {
    color: #8898aa;
    border-radius: 0;
    box-shadow: none !important;
    font-size: 0.7rem;
}
.custom-color-info  .badge-info{
    color: #03ACCA !important;
    background-color: #AAEDF9 !important;
}