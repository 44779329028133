.horizontalRuleLogo {
    border-color: rgba(255, 1, 1, 0.7);
}
.icon-container {
    min-width: 24px;
}
.title-categories {
    margin-top: 24px;
    padding-bottom: 4px;
    color: #212121;
    opacity: 0.8;
    font-weight: 400;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.name-categories { 
    color: #0A66B2;
}
.customActive {
    border-left: 8px solid red;
    padding-left: -10px;
}
.customActive2 {
    margin-left: 0.5rem;
    border-radius: 0.375rem;
    padding-left: calc(1.5rem - 12px) !important;
    border-left: 8px solid red;
    background-color: #f6f9fc;
    padding-left: -10px;
}
.navbar-nav > .nav-item:hover > .nav-link {
    margin-left: 0.5rem;
    border-radius: 0.375rem;
    padding-left: calc(1.5rem - 12px) !important;
    /* border-left: 8px solid red; */
    padding-left: -10px;
    background-color: #f6f9fc;
    transition: .2s;
}
.customActiveNavItem .nav-link span.sidenav-normal{
    color: red !important ;
}
.activeLink {
    color: yellow;
}