.custom-specify-votes h3 {
    font-size: 17px;
}

.custom-specify-votes h4 {
    font-size: 15px;
}

/* .custom-specify-votes input {
    font-size: 13px;
    height: 40px;
} */

.custom-specify-votes input[type=radio] {
    font-size: 13px;
    height: 14px;
}


element.style {
    padding-left: 12px;
    padding-right: 12px;
}

.customSelect {
    font-size: 13px;
    display: flex;
    align-items: center;
}

.customSelect {
    height: 35px;
    min-width: 100px;
}

.customDatePickerWidth {
    padding: 0px 4px;

}

.customDatePickerWidth,
.customDatePickerWidth>div.react-datepicker-wrapper,
.customDatePickerWidth .react-datepicker__input-container,
.customDatePickerWidth input {
    width: 100% !important;
    height: 32px !important;
    border: '1px solid #dee2e6' !important;
}

.customSelect input {
    height: 100%;
}

.customSelect>div {
    width: 100%;
}

.custom-row .row:not(:last-child) {
    margin-bottom: 24px;
}

@media only screen and (max-width: 1600px) {
    .custom-col-6-right {
        margin-top: 24px;
    }

    .custom-col-6-right label {
        min-width: 150px !important;
        max-width: 150px !important;
    }
}
@media only screen and (max-width: 1600px) and (min-width: 1500px) {
    .custom-col-6-right.col-xl-6 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    .custom-col-6-right {
        margin-top: 24px;
    }

    .custom-col-6-right label {
        min-width: 150px !important;
        max-width: 150px !important;
    }
    .custom-col-6-left.col-xl-6 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}