@import "../colors.css";
.container-login {
    width: 100%;
    height: calc(100vh);
    position: relative;
}
.under {
    /* position: absolute;
    bottom: 0;
    width: 100%;
    height: 78vh;
    max-width: 100vw !important; */
}
.bg-under {
    background-image: url(/src/assets/img/background/background.jpg);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    /* aspect-ratio: 40.3/16; */
    width: 100%;
    height: 756px;
}
.textHeadForgot {
    color: #6D8589;
}
.colorRed {
    background-color: red;
}
.colorBlue {
    background-color: blue;
}