.border-red {
    border: 1px solid red !important;
}

.border-gray {
    border: 1px solid #DEE2E6;
}

.input-date-picker-custom {
    padding: 5px 16px 5px 8px !important;
    border-radius: 5px;
    font-size: 0.875rem;
    min-width: 100%;
    width: 100%;
    height: 36px;
    border: 1px solid #DEE2E6;
}

.react-datepicker__input-container {
    position: relative;
}

.icon-calendar .react-datepicker__input-container::after {
    content: url(../../svgs/caleandar.svg);
    width: 14px;
    height: 14px;
    position: absolute;
    top: 0.5rem;
    right: 8px;
}

.icon-clock .react-datepicker__input-container::after {
    content: url(../../svgs/clock.svg);
    width: 12px;
    height: 12px;
    position: absolute;
    top: 0.5rem;
    right: 18px;
}
.is-open-month-container .react-datepicker__month-container{
    display: none;
}
.is-open-month-container .react-datepicker__navigation{
    display: none;
}
.is-open-month-container .react-datepicker__triangle{
    display: none;
}
.react-datepicker__navigation--next {
    /* right: 2rem !important; */
}