.required {
  position: relative;
}

.required::after {
  content: '*';
  position: absolute;
  top: 0;
  left: 100%;
  color: red;
  margin-left: 4px;
}

input[type='file']::file-selector-button {
  margin-right: 10px;
  border: none;
  background: #4690cd;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  font-weight: 600;
}

input[type='file']::file-selector-button:hover {
  background: #4690cd;
}

.selection-cell-header {
  width: 30px;
}

.date-picker-custom div div input {
  /* border-radius: 0.25rem;
  border: 1px solid #dee2e6;
  max-height: 36px;
  height: 36px;
  font-size: 13px; */
}

.date-picker-border div div input {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  margin-left: 0 !important;
  padding-top: 0 !important;
}

.date-picker-border {
  border: 1px solid #dee2e6;
}

.w-200 div div input {
  max-width: 150px;
  padding-left: 8px;
  margin-left: 8px;
}

.row-date-picker-custom {
  padding-left: 8px;
  margin-left: 8px;
  width: 100%;
}


.react-bootstrap-table {
  overflow: auto;
  max-height: 550px;
}

.react-bootstrap-table table thead {
  position: sticky;
  top: 0;
  z-index: 999;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  width: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  width: 5px;
}

.react-bootstrap-table-pagination {
  margin-top: 10px;
}

.custom-input-calendar {
  /* background: url("./assets/svgs/caleandar.svg") no-repeat right;
  background-size: 14px; */
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid #dee2e6;
  max-height: 36px;
  height: 36px;
  font-size: 13px;
  padding: 0px 8px;
}
/* .custom-input-calendar::after {
  content: url(./assets/svgs/caleandar.svg);
    width: 14px;
    height: 14px;
    position: absolute;
    top: 0.5rem;
    right: 8px;
} */


.fixed-column {
  position: sticky;
  right: 0;
  top: auto;
  background: white;
}
tr:hover .fixed-column {
  background: #f6f9fc;
}

/* tr:hover .fixed-column {
  background: #f6f9fc;
} */
.react-select__placeholder {
  white-space: nowrap !important;
}

.custom-date-error {
  border: 1px solid red;
}

.filter {
  position: fixed;
  top: 0;
  right: -400px;
  width: 400px;
  height: 100vh;
  transition: 0.3s;
  z-index: 9999;
}

.show-filter {
  right: 0px;
}

.radio-button-custom .form-check-input {
  margin-top: 0.2rem;
}

.hide-select-items .selection-cell-header{
  width: 0px;
  display: none;
}

.hide-select-items .selection-cell {
  width: 0px;
  display: none;
}

.toggle-update {
  width: 64px;
}
.toggle-before .custom-toggle-slider:before {
left: 14px
}

.custom-navbar-user .nav-item:hover .nav-link {
  color: #333 !important;
  background: transparent;
}
.custom-navbar-user img.avatar-dropdown-toggle {
  width: 36px !important;
  height: 36px !important;
  border-radius: 50%;
}
.custom-navbar-user .avatar {
  width: 36px !important;
  height: 36px !important;
}

@media print{@page {size: landscape}}

.react-select-custom > div > div {
  max-width: 200px;
}

/* begin zoom pcd */
#pcd {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  display: block;
  margin-left: auto;
  margin-right: auto
}

#pcd:hover {opacity: 0.7;}

/* The Modal (background) */
.modal-pcd {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content-pcd {
  margin: auto;
  display: block;
  width: 80%;
}

/* Add Animation */
.modal-content-pcd {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

.out {
animation-name: zoom-out;
animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(1)}
  to {-webkit-transform:scale(2)}
}

@keyframes zoom {
  from {transform:scale(0.4)}
  to {transform:scale(1)}
}

@keyframes zoom-out {
  from {transform:scale(1)}
  to {transform:scale(0)}
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content-pcd {
      width: 100%;
  }
}
/* end zoom */